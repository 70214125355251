@import 'material-icons/iconfont/material-icons.css';

:root {
  --kotini-green: #00a69a;
  --kotini-green-adjusted: #068a85;
  --kotini-red: #d83f3f;
  --kotini-yellow: #ffd371;
  --kotini-black: #0b1621;
  --kotini-black-shades-95: #271f3a;
  --kotini-blue: #6ac6e3;
  --kotini-dark-green: #064550;
  --kotini-green-shade-10: #e5f6f5;
  --kotini-green-shade-20: #ccedeb;
  --kotini-green-shade-30: #b2e4e1;
  --kotini-green-shades-10: #e5f6f5;
  --kotini-green-shades-20: #ccedeb;
  --kotini-green-shades-30: #b2e4e1;
  --kotini-pink: #ffcaca;
  --kotini-white: #ffffff;

  --kotini-beige: #ffefda;
  --kotini-brown: #8a5a3e;
  --kotini-leather: #75472c;
  --kotini-orange: #ffae3e;
  --kotini-coffee: #e3c9a7;
  --kotini-instant: #9e8e7c;
  --kotini-taupe: #c4b7a5;

  --background-0: #e3f4f3;
  --background-1: #caebe9;
  --background-2: #b0e2df;
  --background-3: #80d1cb;
  --background-4: #66cac2;
  --background-5: #4dbfb6;
  --background-6: #33b8ae;
  --background-7: #1aafa4;

  --shade-0: #e8e7ea;
  --shade-1: #d2d0d6;
  --shade-2: #bbb8c1;
  --shade-3: #a4a1ac;
  --shade-4: #8e8998;
  --shade-5: #777183;
  --shade-6: #605a6e;
  --shade-7: #494259;
  --shade-8: #332b45;

  --transparent-light: #ffffff55;
  --transparent-dark: #1c133055;
  --transparent-green: #00a69a19;

  --red: var(--kotini-red);
  --pink: var(--kotini-pink);
  --green: var(--kotini-green);

  --primary-background: var(--kotini-green);
  --primary-foreground: white;
  --primary-adjusted: var(--kotini-green-adjusted);
  --secondary-background: var(--kotini-green-shade-10);
  --secondary-foreground: var(--kotini-black);
  --tertiary-background: transparent;
  --tertiary-foreground: var(--kotini-green-adjusted);
  --inactive-background: var(--background-1);
  --inactive-foreground: var(--kotini-green-adjusted);
  --success-background: var(--kotini-green);
  --success-foreground: white;
  --error-background: white;
  --error-foreground: var(--kotini-black);
  --inactive-secondary-background: var(--shade-1);
  --inactive-secondary-foreground: var(--shade-5);
  --success-background: var(--kotini-green);
  --success-foreground: white;
  --error-background: white;
  --error-foreground: var(--kotini-black);
  --button-background: white;
  --button-foreground: var(--kotini-black);
  --progress-background-primary: var(--background-5);
  --progress-background-secondary: var(--shade-7);
  --pill-background: var(--kotini-green-shade-20);
  --pill-border: var(--kotini-green-shade-30);
  --pill-foreground: var(--kotini-black);

  --notification-good: var(--kotini-green);
  --notification-bad: var(--kotini-red);
}

.faded-10 {
  opacity: 0.1;
}
.faded-20 {
  opacity: 0.2;
}
.faded-30 {
  opacity: 0.3;
}
.faded-40 {
  opacity: 0.4;
}
.faded-50 {
  opacity: 0.5;
}
.faded-60 {
  opacity: 0.6;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  // overflow-y: hidden;
}

body {
  background-color: #fcfaff;
}

a {
  color: inherit;
  text-decoration: none;
}
a.kotini-inline-link {
  color: var(--kotini-green-adjusted);
  text-decoration: underline;
}

h1 {
  font-weight: 700;
  font-size: 48px;
}

h2 {
  font-weight: 600;
  font-size: 32px;
}

h3 {
  font-weight: 500;
  font-size: 24px;
}

h4 {
  font-weight: 500;
  font-size: 16px;
}

p {
  font-weight: 400;
  font-size: 16px;
}

.kotini-card {
  display: flex;
  flex-direction: column;
  padding: 32px 20px 20px 20px;
  gap: 32px;
  border-radius: 32px;
  background: #00a69a0d;

  h1,
  h2,
  h3,
  h4,
  p {
    padding-left: 16px;
  }

  label {
    color: var(--kotini-green-adjusted, #068a85);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 16px;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .kotini-card__form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .kotini-card__formFields {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .kotini-card__formControl {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .kotini-card__formActions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.kotini-icon .cls-2 {
  fill: transparent !important;
}

.kotini-input-select__icon > svg,
.kotini-cta > svg {
  width: 30px;
}

.kotini-button--medium {
  font-weight: 500;
}

.kotini-pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 8px;
  margin-top: 32px;

  li {
    display: flex;
    align-items: center;
    height: 32px;
    gap: 10px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;

    a {
      padding: 0px 16px;
    }
  }

  .selected {
    border: 3px solid var(--kotini-white, #fff);
  }

  :first-child,
  :last-child {
    background: transparent;
  }
}

.kotini-input-select__error-message {
  color: var(--kotini-black);
}

.kotini-navigation__ctas p {
  color: var(--kotini-black);
}

.kotini-alert__title__icon > svg,
.kotini-card-stack__vacant-content > svg {
  width: 30px;
}

.kotini-radio__icon .kotini-icon {
  width: 30px;
  height: 30px;
}

.kotini-animation-spinning {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wobble {
  0%,
  87%,
  89%,
  89%,
  91%,
  100% {
    transform: translateY(0);
  }
  88% {
    transform: translateY(-5px);
  }
  90% {
    transform: translateY(-2px);
  }
}

.homemover-wizard--help {
  width: 100%;
  position: relative;
  height: 100vh;

  /* Tablet Portrait Breakpoint */
  @media only screen and (max-width: 768px) {
    height: 100svh;
  }
}

.kotini-form_guidance,
.kotini-splash_guidance,
.kotini-address_guidance {
  display: flex;
  flex-direction: column;
  gap: 8px;

  ul {
    padding-left: 40px;
  }
}
